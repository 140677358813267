import { combineReducers } from 'redux';

import auth from './auth';
import page from './page';
import settings from './settings';

export default combineReducers({
	page,
	auth,
	settings,
});

import { handleActions } from 'redux-actions';

import pageActions from '../actions/page';

const initialState = {
	title: [],
};

export default handleActions(
	{
		[pageActions.set]: (state, { payload }) => {
			return {
				...state,
				...payload,
			};
		},
		[pageActions.setTitle]: (state, { payload }) => {
			return {
				...state,
				title: payload,
			};
		},
	},
	initialState,
);

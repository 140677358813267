import { createActions } from 'redux-actions';
import {
	API_BASE,
	API_PANEL,
	API_PARTNER,
	API_PARTNER_BASE,
	AUTH as PREFIX,
} from 'store/constants';

const API = `${API_BASE}/auth`;
const API_PARTNER_AUTH = `${API_PARTNER_BASE}/auth`;

const actions = createActions(
	{
		SIGNUP: [
			(payload) => payload, // payload creator
			({ email, password, repassword }) => ({
				url: `${API}/signup`,
				method: 'POST',
				body: {
					grant_type: 'password',
					username: email,
					password: password,
					repassword: repassword,
				},
			}), // meta creator
		], // type = `${GLOBAL}/USER/LOGIN`
		LOGIN: [
			(payload) => payload, // payload creator
			({ email, password }) => ({
				url: `${API}/login`,
				method: 'POST',
				body: {
					grant_type: 'password',
					username: email,
					password: password,
				},
			}), // meta creator
		], // type = `${GLOBAL}/USER/LOGIN`
		LOGOUT: [
			(payload) => payload, // payload creator
			() => ({
				url: `${API}/logout`,
				method: 'POST',
			}), // meta creator
		],
		RESET_PASSWORD: [
			(payload) => payload, // payload creator
			(payload) => ({
				url: `${API}/restore`,
				method: 'POST',
				body: {
					username: payload.email,
				},
			}), // meta creator
		],
		CHANGE_PASSWORD: [
			(payload) => payload, // payload creator
			(payload) => ({
				url: `${API}/changePassword`,
				method: 'POST',
				body: payload,
			}), // meta creator
		],
		ACTIVATE: [
			(payload) => payload, // payload creator
			(payload) => ({
				url: `${API}/getActivate`,
				method: 'GET',
			}), // meta creator
		],
		READ_USER: [
			(payload) => payload, // payload creator
			(payload) => ({
				url: `${API_PANEL}/user`,
				method: 'GET',
			}), // meta creator
		],
		UPDATE_USER: [
			(payload) => payload, // payload creator
			(payload) => ({
				url: `${API_PANEL}/user`,
				method: 'PUT',
				body: payload,
			}), // meta creator
		],
		REFRESH_LOGIN: [
			(payload) => payload, // payload creator
			(token) => ({
				url: `${API}/login`,
				method: 'POST',
				body: {
					grant_type: 'refresh_token',
					refresh_token: token,
				},
			}), // meta creator
		],
	},
	'LOGOUT_SUCCESS',
	'LOGOUT_ERROR',
	'LOGIN_SUCCESS', // type = `${GLOBAL}/USER/LOGIN_SUCCESS`
	'LOGIN_ERROR', // type = `${GLOBAL}/USER/LOGIN_ERROR`
	'RESET_PASSWORD_SUCCESS', // type = `${GLOBAL}/USER/RESET_PASSWORD_SUCCESS`
	'RESET_PASSWORD_ERROR', // type = `${GLOBAL}/USER/RESET_PASSWORD_ERROR`
	'CHANGE_PASSWORD_SUCCESS', // type = `${GLOBAL}/USER/RESET_PASSWORD_SUCCESS`
	'CHANGE_PASSWORD_ERROR', // type = `${GLOBAL}/USER/RESET_PASSWORD_ERROR`,
	'REFRESH_LOGIN_SUCCESS',
	'REFRESH_LOGIN_ERROR',
	'FORBIDDEN',
	'ACTIVATE_SUCCESS',
	'ACTIVATE_ERROR',
	'UPDATE_USER_SUCCESS',
	'UPDATE_USER_ERROR',
	'READ_USER_SUCCESS',
	'READ_USER_ERROR',
	'SIGNUP_SUCCESS',
	'SIGNUP_ERROR',
	// Options
	{
		prefix: `${PREFIX}`, // prefix type
	},
);

actions.partner = createActions(
	{
		SIGNUP: [
			(payload) => payload, // payload creator
			({ email, password, repassword, userclo }) => ({
				backend: 'partner',
				url: `${API_PARTNER_AUTH}/signup`,
				method: 'POST',
				body: {
					grant_type: 'password',
					username: email,
					password: password,
					repassword: repassword,
					userclo,
				},
			}), // meta creator
		], // type = `${GLOBAL}/USER/LOGIN`
		LOGIN: [
			(payload) => payload, // payload creator
			({ email, password }) => ({
				backend: 'partner',
				url: `${API_PARTNER_AUTH}/login`,
				method: 'POST',
				body: {
					grant_type: 'password',
					username: email,
					password: password,
				},
			}), // meta creator
		], // type = `${GLOBAL}/USER/LOGIN`
		LOGOUT: [
			(payload) => payload, // payload creator
			() => ({
				backend: 'partner',
				url: `${API_PARTNER_AUTH}/logout`,
				method: 'POST',
			}), // meta creator
		],
		RESET_PASSWORD: [
			(payload) => payload, // payload creator
			(payload) => ({
				backend: 'partner',
				url: `${API_PARTNER_AUTH}/restore`,
				method: 'POST',
				body: {
					username: payload.email,
				},
			}), // meta creator
		],
		CHANGE_PASSWORD: [
			(payload) => payload, // payload creator
			(payload) => ({
				backend: 'partner',
				url: `${API_PARTNER_AUTH}/changePassword`,
				method: 'POST',
				body: payload,
			}), // meta creator
		],
		ACTIVATE: [
			(payload) => payload, // payload creator
			(payload) => ({
				backend: 'partner',
				url: `${API_PARTNER_AUTH}/getActivate`,
				method: 'GET',
			}), // meta creator
		],
		READ_USER: [
			(payload) => payload, // payload creator
			(payload) => ({
				backend: 'partner',
				url: `${API_PARTNER}/user`,
				method: 'GET',
			}), // meta creator
		],
		UPDATE_USER: [
			(payload) => payload, // payload creator
			(payload) => ({
				backend: 'partner',
				url: `${API_PARTNER}/user`,
				method: 'PUT',
				body: payload,
			}), // meta creator
		],
		REFRESH_LOGIN: [
			(payload) => payload, // payload creator
			(token) => ({
				backend: 'partner',
				url: `${API_PARTNER_AUTH}/login`,
				method: 'POST',
				body: {
					grant_type: 'refresh_token',
					refresh_token: token,
				},
			}), // meta creator
		],
	},
	'LOGOUT_SUCCESS',
	'LOGOUT_ERROR',
	'LOGIN_SUCCESS', // type = `${GLOBAL}/USER/LOGIN_SUCCESS`
	'LOGIN_ERROR', // type = `${GLOBAL}/USER/LOGIN_ERROR`
	'RESET_PASSWORD_SUCCESS', // type = `${GLOBAL}/USER/RESET_PASSWORD_SUCCESS`
	'RESET_PASSWORD_ERROR', // type = `${GLOBAL}/USER/RESET_PASSWORD_ERROR`
	'CHANGE_PASSWORD_SUCCESS', // type = `${GLOBAL}/USER/RESET_PASSWORD_SUCCESS`
	'CHANGE_PASSWORD_ERROR', // type = `${GLOBAL}/USER/RESET_PASSWORD_ERROR`,
	'REFRESH_LOGIN_SUCCESS',
	'REFRESH_LOGIN_ERROR',
	'FORBIDDEN',
	'ACTIVATE_SUCCESS',
	'ACTIVATE_ERROR',
	'UPDATE_USER_SUCCESS',
	'UPDATE_USER_ERROR',
	'READ_USER_SUCCESS',
	'READ_USER_ERROR',
	'SIGNUP_SUCCESS',
	'SIGNUP_ERROR',
	// Options
	{
		prefix: `${PREFIX}/PARTNER`, // prefix type
	},
);

export default actions;

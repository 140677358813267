/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import { SHOW, TRANSFORM, CLOSE, CHANGE, LOADING } from './constants';

export const swal = (payload, callback) => {
	return {
		type: TRANSFORM,
		payload,
		callback,
	};
};

export const show = (payload, callback) => {
	return {
		type: SHOW,
		payload,
		callback,
	};
};

export const close = (payload, callback) => {
	return {
		type: CLOSE,
		payload,
		callback,
	};
};

export const change = (payload, callback) => {
	return {
		type: CHANGE,
		payload,
		callback,
	};
};

export const loading = (payload, callback) => {
	return {
		type: LOADING,
		payload: {
			showConfirm: false,
			showCancel: false,
			message: '',
			title: '',
			...payload,
		},
		callback,
	};
};

const actions = {
	swal,
	show,
	close,
	loading,
	change,
};

export default actions;

import invariant from 'invariant';

import defaultsDeep from 'lodash/defaultsDeep';

import { loadState, saveState } from './localStorage';

var randomString = function randomString() {
	return Math.random().toString(36).substring(7).split('').join('.');
};

const ActionTypes = {
	INIT: '@@persistReducer/INIT' + randomString(),
};

const persistors = new Map();

export default function persistReducer(config, baseReducer) {
	if (process.env.NODE_ENV !== 'production') {
		invariant(!!config, '(app/utils...) persistReducer: config is required');
		invariant(
			!!config.key,
			'(app/utils...) persistReducer: key is required in persistor config',
		);
	}

	const version = config.version !== undefined ? config.version : -1;

	let persist = persistors.get(config.key);

	if (!persist) {
		persist = {
			version,
			rehydrated: false,
			state: loadState(config.key),
		};
		persistors.set(config.key, persist);
	}

	function passWhitelistBlacklist(key) {
		if (config.whitelist && config.whitelist.indexOf(key) === -1) return false;
		if (config.blacklist && config.blacklist.indexOf(key) !== -1) return false;
		return true;
	}

	const parse = (state) => {
		const parsedState = {};
		Object.keys(state).forEach((key) => {
			if (!passWhitelistBlacklist(key)) return; // is keyspace ignored? noop
			parsedState[key] = state[key];
		});
		return parsedState;
	};

	return (state, action) => {
		let newState;

		if (state) {
			if (persist.rehydrated) {
				newState = baseReducer(state, action);
				saveState(config.key, parse(newState));
			} else {
				const initialState = baseReducer(undefined, {
					type: ActionTypes.INIT,
				});
				newState = baseReducer(persist.state, action);
				defaultsDeep(newState, initialState);
				if (state) {
					persist.rehydrated = true;
					persist.state = null;
				}
			}
		} else {
			newState = baseReducer(state, action);
		}

		return newState;
	};
}

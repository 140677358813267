import * as sweetAlert from 'components/SweetAlert/actions';
import { push } from 'connected-react-router';
import { channel } from 'redux-saga';
import { race, select, take, call, put, takeLeading, all } from 'redux-saga/effects';
import actions from 'store/global/actions/auth';
import {
	selectTokens,
	selectUser,
	selectPartnerTokens,
	selectPartnerUser,
} from 'store/global/selectors';
import { showError } from 'utils/notify';
import { requestBasic, requestBearer } from 'utils/request';

const actionChannel = channel();

function* watchActionChannel() {
	while (true) {
		const action = yield take(actionChannel);
		yield put(action);
	}
}

export function* refreshLogin({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const data = yield call(requestBasic, url, options);

		yield put(actions.refreshLoginSuccess(data));
	} catch (error) {
		yield put(actions.refreshLoginError(error));
		showError(error);
	}
}

export function* signup({ payload, meta }) {
	try {
		if (payload.password !== payload.repassword) {
			yield put(
				sweetAlert.swal({
					type: 'danger',
					message: 'Пароли не совпадают',
				}),
			);
		} else {
			yield put(
				sweetAlert.loading({
					showConfirm: false,
					showCancel: false,
					message: '',
					title: '',
				}),
			);
			const { url, ...options } = meta;
			const data = yield call(requestBasic, url, options);

			yield put(actions.partner.signup(payload));
			const partnerRequest = yield race({
				success: take(actions.partner.signupSuccess),
				error: take(actions.partner.signupError),
			});
			if (partnerRequest.error) {
				throw partnerRequest.error.payload;
			}
			yield put(
				actions.loginSuccess({
					clo: data,
					partner: partnerRequest.success.payload,
				}),
			);

			yield put(sweetAlert.close());
			yield put(push('/'));
		}
	} catch (error) {
		yield put(actions.loginError(error));
		yield put(
			sweetAlert.swal({
				type: 'danger',
				message: error.message,
			}),
		);
	}
}

export function* resetPassword({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		yield put(
			sweetAlert.loading({
				showConfirm: false,
				showCancel: false,
				message: '',
				title: '',
			}),
		);
		const data = yield call(requestBasic, url, options);

		yield put(
			sweetAlert.swal({
				type: 'success',
				title: '',
				message: `На адрес ${payload.email} было отправленно письмо с инструкцией по восстановлению пароля.`,
			}),
		);
		yield put(actions.resetPasswordSuccess(data));
	} catch (error) {
		yield put(actions.resetPasswordError(error));
		yield put(
			sweetAlert.swal({
				type: 'danger',
				message: error.message,
			}),
		);
	}
}

export function* login({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const data = yield call(requestBasic, url, options);

		yield put(actions.partner.login({ userclo: data.user._id, ...payload }));

		const partnerRequest = yield race({
			success: take(actions.partner.loginSuccess),
			error: take(actions.partner.loginError),
		});
		if (partnerRequest.error) {
			throw partnerRequest.error.payload;
		}
		yield put(
			actions.loginSuccess({
				clo: data,
				partner: partnerRequest.success.payload,
			}),
		);

		yield put(push('/'));
	} catch (error) {
		yield put(actions.loginError(error));
		yield put(
			sweetAlert.swal({
				type: 'danger',
				message: error.message,
			}),
		);
	}
}

export function* logout({ meta, payload }) {
	try {
		const { url, ...options } = meta;
		const tokens = yield select(selectTokens);
		const user = yield select(selectUser);
		yield call(requestBasic, url, {
			...options,
			body: {
				...tokens,
				userId: user._id,
			},
		});
		yield put(actions.partner.logout(payload));
	} catch (error) {}
	yield put(actions.logoutSuccess());
	yield put(push('/login'));
}

export function* changePassword({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const res = yield call(requestBasic, url, options);

		yield put(actions.partner.changePassword({ userclo: res.user._id, ...payload }));
		const partnerRequest = yield race({
			success: take(actions.partner.changePasswordSuccess),
			error: take(actions.partner.changePasswordError),
		});

		if (partnerRequest.error) {
			throw partnerRequest.error.payload;
		}

		yield put(actions.changePasswordSuccess(res));
		yield put(actions.login({ email: res.username, password: res.password }));
	} catch (error) {
		yield put(
			sweetAlert.swal({
				type: 'danger',
				title: error.message,
			}),
		);
	}
}

export function* readUser({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const data = yield call(requestBearer, url, options);
		yield put(actions.readUserSuccess(data));
	} catch (error) {
		console.error(error);
	}
}

export function* activate({ payload, meta }) {
	try {
		yield put(
			sweetAlert.loading({
				showConfirm: false,
				showCancel: false,
				message: '',
				title: '',
			}),
		);
		const { url, ...options } = meta;
		const data = yield call(requestBearer, url, options);
		yield put(actions.activateSuccess(data));

		const user = yield select(selectUser);

		yield put(
			sweetAlert.swal({
				type: 'success',
				title: '',
				message: `Письмо с сcылкой для активации было отправлено по адресу ${user.username}, если письмо не пришло проверьте папку спам.`,
			}),
		);
	} catch (error) {
		yield put(actions.activateError(error));

		yield put(
			sweetAlert.swal({
				type: 'danger',
				title: '',
				message: error.message,
			}),
		);
	}
}

export function* updateUser({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const data = yield call(requestBearer, url, options);
		yield put(
			sweetAlert.swal({
				type: 'success',
				title: 'Данные обновлены',
			}),
		);

		yield put(actions.partner.updateUser(payload));
		const partnerRequest = yield race({
			success: take(actions.partner.updateUserSuccess),
			error: take(actions.partner.updateUserError),
		});

		if (partnerRequest.error) {
			throw partnerRequest.error.payload;
		}

		yield put(actions.updateUserSuccess(data));
	} catch (error) {
		yield put(
			sweetAlert.swal({
				type: 'danger',
				title: error.message,
			}),
		);
	}
}

export function* refreshLoginPartner({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const data = yield call(requestBasic, url, options);

		yield put(actions.partner.refreshLoginSuccess(data));
	} catch (error) {
		yield put(actions.partner.refreshLoginError(error));
		showError(error);
	}
}

export function* signupPartner({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const data = yield call(requestBasic, url, options);
		yield put(actions.partner.signupSuccess(data));
	} catch (error) {
		yield put(actions.partner.signupError(error));
	}
}

export function* loginPartner({ payload, meta }) {
	const { url, ...options } = meta;
	try {
		const data = yield call(requestBasic, url, options);
		yield put(actions.partner.loginSuccess(data));
	} catch (error) {
		try {
			const response = error.response || {};
			if (response.status === 403) {
				yield put(actions.partner.signup(payload));

				const requestSignup = yield race({
					success: take(actions.partner.signupSuccess),
					error: take(actions.partner.signupError),
				});

				if (requestSignup.error) {
					throw requestSignup.error.payload;
				}

				const data = yield call(requestBasic, url, options);
				yield put(actions.partner.loginSuccess(data));
			} else {
				throw error;
			}
		} catch (error) {
			yield put(actions.partner.loginError(error));
		}
	}
}

export function* logoutPartner({ meta, payload }) {
	try {
		const { url, ...options } = meta;
		const tokens = yield select(selectPartnerTokens);
		const user = yield select(selectPartnerUser);
		yield call(requestBasic, url, {
			...options,
			body: {
				...tokens,
				userId: user._id,
			},
		});
	} catch (error) {}
	yield put(actions.partner.logoutSuccess());
}

export function* changePasswordPartner({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const res = yield call(requestBasic, url, options);
		yield put(actions.partner.changePasswordSuccess(res));
	} catch (error) {
		yield put(actions.partner.changePasswordError(error));
	}
}

export function* readUserPartner({ payload, meta }) {
	try {
		const { url, ...options } = meta;
		const data = yield call(requestBearer, url, options);
		yield put(actions.partner.readUserSuccess(data));
	} catch (error) {
		console.error(error);
	}
}

export function* updateUserPartner({ meta }) {
	try {
		const { url, ...options } = meta;
		const data = yield call(requestBearer, url, options);
		yield put(actions.partner.updateUserSuccess(data));
	} catch (error) {
		yield put(actions.partner.updateUserError(error));
	}
}

export default function* authSaga() {
	yield all([
		watchActionChannel(),
		takeLeading(actions.signup, signup),
		takeLeading(actions.login, login),
		takeLeading(actions.logout, logout),
		takeLeading(actions.resetPassword, resetPassword),
		takeLeading(actions.refreshLogin, refreshLogin),
		takeLeading(actions.changePassword, changePassword),
		takeLeading(actions.readUser, readUser),
		takeLeading(actions.updateUser, updateUser),
		takeLeading(actions.activate, activate),

		takeLeading(actions.partner.signup, signupPartner),
		takeLeading(actions.partner.login, loginPartner),
		takeLeading(actions.partner.logout, logoutPartner),
		takeLeading(actions.partner.refreshLogin, refreshLoginPartner),
		takeLeading(actions.partner.changePassword, changePasswordPartner),
		takeLeading(actions.partner.readUser, readUserPartner),
		takeLeading(actions.partner.updateUser, updateUserPartner),
	]);
}

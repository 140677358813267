import { handleActions } from 'redux-actions';

import actions from '../actions/auth';

const initialState = {
	loading: false,
	error: false,
	isAuth: false,
	payload: {
		clo: {
			access_token: null,
			refresh_token: null,
			user: {
				loading: false,
				access: {},
				tariff: {
					variant: null,
					expires: Date.now(),
				},
				lastActivity: [],
			},
		},
		partner: {
			access_token: null,
			refresh_token: null,
			user: {
				access: {},
				stats: {
					money: {},
				},
				settings: {
					payment: [],
				},
				domains: [],
				ips: [],
				offers: [],
				favoriteOffers: [],
				loading: false,
			},
		},
	},
};

export default handleActions(
	{
		[actions.login]: (state, action) => {
			return {
				...state,
				error: false,
				loading: true,
			};
		},
		[actions.loginSuccess]: (state, { payload }) => {
			return {
				...state,
				error: false,
				isAuth: true,
				loading: false,
				payload,
			};
		},
		[actions.loginError]: (state, { error }) => {
			return {
				...initialState,
				loading: false,
				isAuth: false,
				error,
			};
		},
		[actions.refreshLogin]: (state, action) => {
			return {
				...state,
				error: false,
			};
		},
		[actions.refreshLoginSuccess]: (state, { payload }) => {
			return {
				...state,
				error: false,
				isAuth: true,
				loading: false,
				payload: {
					...state.payload,
					clo: payload,
				},
			};
		},
		[actions.refreshLoginError]: (state, { error }) => {
			return {
				...initialState,
				loading: false,
				isAuth: false,
				error,
			};
		},
		[actions.readUserSuccess]: (state, { payload }) => {
			return {
				...state,
				payload: {
					...state.payload,
					clo: {
						...state.payload.clo,
						user: payload,
					},
				},
			};
		},
		[actions.updateUser]: (state, { payload }) => {
			return {
				...state,
				payload: {
					...state.payload,
					clo: {
						...state.payload.clo,
						user: {
							...state.payload.clo.user,
							loading: true,
						},
					},
				},
			};
		},
		[actions.updateUserSuccess]: (state, { payload }) => {
			return {
				...state,
				payload: {
					...state.payload,
					clo: {
						...state.payload.clo,
						user: {
							...state.payload.clo.user,
							loading: false,
						},
					},
				},
			};
		},
		[actions.updateUserError]: (state, { error }) => {
			return {
				...state,
				payload: {
					...state.payload,
					clo: {
						...state.payload.clo,
						user: {
							...state.payload.clo.user,
							loading: false,
							error,
						},
					},
				},
			};
		},
		[actions.logout]: (state) => state,
		[actions.logoutSuccess]: () => initialState,

		//=========PARTNER ACTIONS =========//
		// [actions.partner.login]: (state,action) => {
		//   return {
		//     ...state,
		//     error: false,
		//     loading: true
		//   }
		// },
		// [actions.partner.loginSuccess]: (state,{payload}) => {
		//   return {
		//     ...state,
		//     error: false,
		//     isAuth: true,
		//     loading: false,
		//     payload
		//   }
		// },
		// [actions.partner.loginError]: (state,{error}) => {
		//   return {
		//     ...initialState,
		//     loading: false,
		//     isAuth: false,
		//     error,
		//   }
		// },
		[actions.partner.refreshLogin]: (state, action) => {
			return {
				...state,
				error: false,
			};
		},
		[actions.partner.refreshLoginSuccess]: (state, { payload }) => {
			return {
				...state,
				error: false,
				isAuth: true,
				loading: false,
				payload: {
					...state.payload,
					partner: payload,
				},
			};
		},
		[actions.partner.refreshLoginError]: (state, { error }) => {
			return {
				...initialState,
				loading: false,
				isAuth: false,
				error,
			};
		},
		[actions.partner.readUserSuccess]: (state, { payload }) => {
			return {
				...state,
				payload: {
					...state.payload,
					partner: {
						...state.payload.partner,
						user: payload,
					},
				},
			};
		},
		[actions.partner.updateUser]: (state, { payload }) => {
			return {
				...state,
				payload: {
					...state.payload,
					partner: {
						...state.payload.partner,
						user: {
							...state.payload.partner.user,
							loading: true,
						},
					},
				},
			};
		},
		[actions.partner.updateUserSuccess]: (state, { payload }) => {
			return {
				...state,
				payload: {
					...state.payload,
					partner: {
						...state.payload.partner,
						user: {
							...state.payload.partner.user,
							loading: false,
						},
					},
				},
			};
		},
		[actions.partner.updateUserError]: (state, { error }) => {
			return {
				...state,
				payload: {
					...state.payload,
					partner: {
						...state.payload.partner,
						user: {
							...state.payload.partner.user,
							loading: false,
							error,
						},
					},
				},
			};
		},
		[actions.partner.logout]: (state) => state,
		[actions.partner.logoutSuccess]: () => initialState,
	},
	initialState,
);

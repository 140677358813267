export default function parseError(error) {
	if (error.connectionEnabled) {
		let body;

		if (error.body) {
			body = error.body;
		}

		if (body) {
			error = {
				...error,
				...body,
				message:
					body.message || body.error_description || 'Произошла непредвиденная ошибка',
				code: body.code,
			};
		} else {
			error = {
				...error,
				status: error.response?.status || 500,
				message: error.message || 'Произошла непредвиденная ошибка',
			};
		}
	} else {
		error = {
			...error,
			status: 499,
			message: 'Проверьте подключение к интернету',
		};
	}

	return error;
}

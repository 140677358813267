/**
 * Asynchronously loads the component for HomePage
 */
import loadable from '@loadable/component';

// import pMinDelay from 'p-min-delay'
import Loading from 'components/Loading';
import { timeout, TimeoutError } from 'promise-timeout';

export default loadable(
	() => {
		const timeoutImport = timeout(import('./index'), 10000).catch((err) => {
			if (err instanceof TimeoutError) {
				err.status = 408;
			}
			throw err;
		});
		// return pMinDelay(timeoutImport, 200);
		return timeoutImport;
	},
	{
		fallback: Loading(),
	},
);

import useActions from 'utils/useActions';

import authActions from './auth';
import pageActions from './page';
import settingsActions from './settings';

export function usePageActions(deps) {
	return useActions(pageActions, deps);
}

export function useAuthActions(deps) {
	return useActions(authActions, deps);
}

export function useSettingsActions(deps) {
	return useActions(settingsActions, deps);
}

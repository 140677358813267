import FontAwesomeIcon from 'components/FontAwesomeIcon';
import getExpiresTariff from 'components/utils/getExpiresTariff';
import React, { useState, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';

import { useAuthActions } from 'store/global/actions';
import { useTitle, useUser, useLocation, usePartnerUser } from 'store/global/selectors';

const NotificationsComponent = React.memo(function Notifications(props) {
	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);

	const handleClick = (event) => {
		setShow(!show);
		setTarget(event.target);
	};

	const handleClose = () => setShow(false);

	return (
		<Nav.Item>
			<Nav.Link onClick={handleClick} className={`${props.textExpires ? ' beep' : ''}`}>
				<FontAwesomeIcon icon={'bell'} size="lg" />
			</Nav.Link>

			<Overlay
				show={show}
				target={target}
				placement="bottom"
				rootClose={true}
				onHide={handleClose}
			>
				<Popover id="popover-basic" style={{ display: 'table' }}>
					<Popover.Title>
						Уведомления
						<button type="button" className="close" onClick={handleClose}>
							<span aria-hidden="true">×</span>
							<span className="sr-only">Закрыть</span>
						</button>
					</Popover.Title>
					<Popover.Content className="popover-list notification">
						<div className="popover-list-icons">
							{props.textExpires ? (
								<a
									target={'_blank'}
									rel="noopener noreferrer"
									href="https://t.me/dzhaweb/"
									className="popover-item text-body"
									onClick={handleClose}
								>
									<div className="popover-item-icon bg-danger text-white">
										<FontAwesomeIcon icon={'exclamation'} size="lg" />
									</div>
									<div className="popover-item-desc">
										{props.textExpires}
										<div className="time">
											Кликните для продления подписки, вы будете
											перенапрвавлены в чат поддержки.
										</div>
									</div>
								</a>
							) : (
								<div className="popover-footer text-center">
									<span>Ничего важного не произошло.</span>
								</div>
							)}
						</div>
					</Popover.Content>
				</Popover>
			</Overlay>
		</Nav.Item>
	);
});

const UserInfo = React.memo(({ expiresTitle }) => {
	const authActions = useAuthActions();
	const onLogout = authActions.logout;

	const user = useUser();
	const username = user.username;

	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const handleClick = (event) => {
		setShow(!show);
		setTarget(event.target);
	};

	const handleClose = () => setShow(false);

	return (
		<Nav.Item>
			<Nav.Link onClick={handleClick}>
				<FontAwesomeIcon icon={'user-circle'} size="lg" />
				<span className="d-none d-sm-inline">{` ${username}`}</span>
			</Nav.Link>

			<Overlay
				show={show}
				target={target}
				placement="bottom"
				rootClose={true}
				onHide={handleClose}
			>
				<Popover>
					<ListGroup variant="flush">
						<ListGroup.Item className="rounded-top d-sm-none">
							<FontAwesomeIcon icon={'user-circle'} size="lg" />
							{` ${username}`}
						</ListGroup.Item>
						<ListGroup.Item
							className="rounded-top"
							action
							as={Link}
							to="/clo/payments/"
							onClick={handleClose}
						>
							{/* <FontAwesomeIcon icon={['fab', 'connectdevelop']} size="lg" /> */}
							{` ${expiresTitle}`}
						</ListGroup.Item>
						<ListGroup.Item
							className="rounded-top"
							action
							as={Link}
							to="/web/settings"
							onClick={handleClose}
						>
							<FontAwesomeIcon icon={'user-cog'} size="lg" />
							{' Настройки'}
						</ListGroup.Item>
						<ListGroup.Item className="rounded-bottom" action onClick={onLogout}>
							<FontAwesomeIcon icon={'sign-out-alt'} size="lg" /> Выход
						</ListGroup.Item>
					</ListGroup>
				</Popover>
			</Overlay>
		</Nav.Item>
	);
});

const BalanceInfo = React.memo(() => {
	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const handleClick = (event) => {
		setShow(!show);
		setTarget(event.target);
	};

	const handleClose = () => setShow(false);

	const { stats } = usePartnerUser();

	return (
		<Nav.Item>
			<Nav.Link onClick={handleClick}>
				<FontAwesomeIcon icon={'money-bill'} size="lg" />
				{` Баланс`}
			</Nav.Link>

			<Overlay
				show={show}
				target={target}
				placement="bottom"
				rootClose={true}
				onHide={handleClose}
			>
				<Popover>
					<ListGroup variant="flush" className="min-w-200px">
						<ListGroup.Item className="rounded-top" action as={Link} to="/web/finances">
							<h4 className="mb-1">Одобрено:</h4>
							<h4 className="mb-0">
								{stats.money.notPaid}
								&#36;
							</h4>
						</ListGroup.Item>
						<ListGroup.Item
							className="rounded-bottom"
							action
							as={Link}
							to="/web/finances"
						>
							<h4 className="mb-1">Баланс:</h4>
							<h4 className="mb-0">
								{stats.money.onBalance}
								&#36;
							</h4>
						</ListGroup.Item>
						<ListGroup.Item
							className="rounded-bottom"
							action
							as={Link}
							to="/web/finances"
						>
							<h4 className="mb-1">Выплачено:</h4>
							<h4 className="mb-0">
								{stats.money.paid}
								&#36;
							</h4>
						</ListGroup.Item>
					</ListGroup>
				</Popover>
			</Overlay>
		</Nav.Item>
	);
});

const TitleComponent = function Title({ route }) {
	const title = useTitle();
	const location = useLocation();

	return (
		<Nav.Item className="navbar__title d-none d-sm-block">
			{title.map(({ path, label }, i) => {
				if (title.length === i + 1) {
					return (
						<Link
							key={path}
							to={{
								pathname: path,
								state: location.state,
							}}
							replace
						>
							{label}
						</Link>
					);
				} else {
					return (
						<React.Fragment key={path}>
							<Link to={path}>{label}</Link>
							{' - '}
						</React.Fragment>
					);
				}
			})}
		</Nav.Item>
	);
};

export default React.memo(function Header({ fluid }) {
	const toggle_sidebar_mini = useCallback((mini) => {
		const body = document.body;

		if (!mini) {
			body.classList.remove('sidebar-mini');
		} else {
			body.classList.add('sidebar-mini');
			body.classList.remove('sidebar-show');
		}
	}, []);

	const toggleSidebar = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			const body = document.body;
			if (window.innerWidth < 992) {
				body.classList.remove('search-show', 'search-gone');
				if (body.classList.contains('sidebar-gone')) {
					body.classList.remove('sidebar-gone');
					body.classList.add('sidebar-show');
				} else {
					body.classList.add('sidebar-gone');
					body.classList.remove('sidebar-show');
				}
			} else {
				body.classList.remove('search-show', 'search-gone');
				if (body.classList.contains('sidebar-mini')) {
					toggle_sidebar_mini(false);
				} else {
					toggle_sidebar_mini(true);
				}
			}
		},
		[toggle_sidebar_mini],
	);

	const { tariff } = useUser();
	const tariffExpires = new Date(tariff.expires);
	const expires = tariffExpires.getTime() > Date.now() ? tariffExpires.toLocaleDateString() : '';
	const expiresTitle = `Подписка CLO ${expires ? 'до ' + expires : 'истекла'}`;
	const textExpires = getExpiresTariff(tariffExpires);

	const authActions = useAuthActions();
	const onLogout = authActions.logout;

	return (
		<Navbar variant="main" bg="dark" expand="xs">
			<div className="navbar-bg"></div>
			<Container fluid={fluid}>
				<Nav className="d-lg-none mr-3">
					<Nav.Item onClick={toggleSidebar}>
						<FontAwesomeIcon icon={'bars'} size="lg" />
					</Nav.Item>
				</Nav>

				<Nav className="mr-auto">
					<TitleComponent />
				</Nav>

				<Nav className="ml-auto">
					<NotificationsComponent textExpires={textExpires} />

					<BalanceInfo />

					<UserInfo expiresTitle={expiresTitle} />

					<OverlayTrigger
						placement="left-end"
						overlay={<Tooltip id={`navbar_logout`}>Выйти</Tooltip>}
					>
						<Nav.Item>
							<Nav.Link onClick={onLogout}>
								<FontAwesomeIcon icon={'sign-out-alt'} size="lg" />
							</Nav.Link>
						</Nav.Item>
					</OverlayTrigger>
				</Nav>
			</Container>
		</Navbar>
	);
});

import ErrorPage from 'components/ErrorPage';
import React from 'react';
import { Link } from 'react-router-dom';

export default React.memo(function NotFoundPage(props) {
	return (
		<ErrorPage status="404" message="К сожалению, эта страница недоступна.">
			Возможно вы воспользовались недействительной ссылкой или страница была удалена.
			<div className="mt-3">
				<Link to="/">Перейти на главную.</Link>
			</div>
		</ErrorPage>
	);
});

import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import './style.scss';

function Footer({ fluid, className, ...props }) {
	className = `main-footer ${className}`;
	return (
		<footer className={className} {...props}>
			<Container fluid={fluid}>
				<Row className="align-items-center justify-content-md-between">
					<Col md={6}>
						<div className="copyright text-center text-md-left text-muted">
							Copyright © {new Date().getFullYear()}
							{/* <a className="font-weight-bold ml-1" href="https://vk.com/dzhaweb" target="_blank" rel="noopener noreferrer" >Vkontakte</a> */}
							{`  `}
							<a
								className="font-weight-bold ml-1"
								href="https://t.me/dzhaweb/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Telegram
							</a>
						</div>
					</Col>
					<Col md={6}>
						<Nav className="nav-footer justify-content-center justify-content-md-end">
							{/* <Nav.Item>
								<Nav.Link
									className="text-muted"
									href="https://t.me/dzhaweb/"
									target="_blank"
									rel="noopener noreferrer"
								>
									По всем вопросам
								</Nav.Link>
							</Nav.Item> */}
							<Nav.Item>
								<Nav.Link
									className="text-muted"
									href="https://t.me/dzhaweb"
									target="_blank"
									rel="noopener noreferrer"
								>
									Тех. поддержка
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default React.memo(Footer);

import invariant from 'invariant';
import get from 'lodash/get';
import has from 'lodash/has';

export default function makeSelect(key = '') {
	const selector = (state) => {
		const selected = get(state, key);

		if (process.env.NODE_ENV === `development`) {
			const keys = Array.isArray(key) ? key : key.split(',');
			invariant(
				has(state, key),
				`(api/utils...) makeSelector: selected state by path: { ${keys.join(
					'.',
				)} } not found, check reducer ${keys[0]}`,
			);
		}
		return selected;
	};

	return selector;
}

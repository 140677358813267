import { all } from 'redux-saga/effects';

import authSaga from './auth';
import settings from './settings';

/**
 * Root saga manages watcher lifecycle
 */
export default function* globalSaga() {
	yield all([authSaga(), settings()]);
}

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

function bindNestedActionCreators(actions, dispatch) {
	const flatActions = { ...actions };
	const nestedActions = {};

	for (const key in flatActions) {
		if (typeof flatActions[key] !== 'function') {
			nestedActions[key] = flatActions[key];
			delete flatActions[key];
		}
	}

	const result = bindActionCreators(flatActions, dispatch);

	for (const key in nestedActions) {
		result[key] = bindNestedActionCreators(nestedActions[key], dispatch);
	}

	return result;
}

export default function useActions(actions, deps) {
	const dispatch = useDispatch();

	return useMemo(
		() => {
			if (Array.isArray(actions)) {
				return actions.map((a) => bindActionCreators(a, dispatch));
			}

			return bindNestedActionCreators(actions, dispatch);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps ? [actions, dispatch, ...deps] : [actions, dispatch],
	);
}

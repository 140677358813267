import routes from 'containers/routes';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';
import { GLOBAL_KEY } from 'store/constants';
import makeSelector from 'utils/makeSelector';

import { useSettingsActions } from './actions';

const useReselectSelector = (selector) => {
	return useSelector((state) => selector(state));
};

export const selectRouter = createSelector(makeSelector(['router']), (router) => router);

export const selectLocation = createSelector(
	makeSelector(['router', 'location']),
	(location) => location,
);

export const selectRoute = createSelector(selectLocation, ({ pathname }) => {
	for (let i = 0; i < routes.length; i++) {
		const route = routes[i];
		const match = matchPath(pathname, route);
		if (match) {
			return [route, match];
		}
	}
	const route = routes[0];
	const match = matchPath(route.to || route.path, route);
	return [route, match];
});

export const selectAuth = createSelector(makeSelector([GLOBAL_KEY, 'auth']), (auth) => auth);

export const selectTokens = createSelector(
	makeSelector([GLOBAL_KEY, 'auth', 'payload', 'clo']),
	(payload) => ({
		access_token: payload.access_token,
		refresh_token: payload.refresh_token,
	}),
);
export const selectUser = createSelector(
	makeSelector([GLOBAL_KEY, 'auth', 'payload', 'clo', 'user']),
	(user) => user,
);

export const selectPartnerTokens = createSelector(
	makeSelector([GLOBAL_KEY, 'auth', 'payload', 'partner']),
	(payload) => ({
		access_token: payload.access_token,
		refresh_token: payload.refresh_token,
	}),
);

export const selectPartnerUser = createSelector(
	makeSelector([GLOBAL_KEY, 'auth', 'payload', 'partner', 'user']),
	(user) => user,
);

export const selectPage = createSelector(makeSelector([GLOBAL_KEY, 'page']), (page) => page);

export const selectTitle = createSelector(
	makeSelector([GLOBAL_KEY, 'page', 'title']),
	(title) => title,
);

export const selectSettings = createSelector(
	makeSelector([GLOBAL_KEY, 'settings']),
	(title) => title,
);

export function useAuth() {
	return useReselectSelector(selectAuth);
}

export function useTokens() {
	return useReselectSelector(selectTokens);
}

export function useUser() {
	return useReselectSelector(selectUser);
}

export function usePartnerTokens() {
	return useReselectSelector(selectPartnerTokens);
}

export function usePartnerUser() {
	return useReselectSelector(selectPartnerUser);
}

export function useRouter() {
	return useReselectSelector(selectRouter);
}

export function useLocation() {
	return useReselectSelector(selectLocation);
}

export function usePage() {
	return useReselectSelector(selectPage);
}

export function useRoute() {
	return useReselectSelector(selectRoute);
}

export function useTitle() {
	return useReselectSelector(selectTitle);
}

export function useSettings() {
	const actions = useSettingsActions();
	useEffect(() => {
		actions.read();
	}, [actions]);
	return useReselectSelector(selectSettings);
}

import iziToast from 'izitoast';

export const showOnceError = (function () {
	let isShow = false;

	return function showOnceError(error, title) {
		const message = error.message || error;
		if (!isShow) {
			iziToast.error({
				position: 'bottomRight',
				title: title || 'Ошибка',
				message: message,
				onOpening: () => (isShow = true),
				onOpened: () => (isShow = true),
				onClosing: () => (isShow = false),
				onClosed: () => (isShow = false),
			});
		}
	};
})();

export function showError(error = {}, title) {
	const status = error.response?.status;
	if (!error.connectionEnabled) {
		showOnceError('Проверьте подключение к интернету!');
	} else if (status === 502) {
		showOnceError('Сервер не отвечает, попробуйте позже');
	} else if (status === 504) {
		showOnceError('Истекло время ожидания, попробуйте позже');
	} else if (!error.hidden) {
		iziToast.error({
			position: 'bottomRight',
			title: title || 'Ошибка',
			message: error.message,
		});
	}
}

export function showSuccess(message, title) {
	iziToast.success({
		position: 'bottomRight',
		title: title || 'Успешно',
		message: message || 'Запрос обработан',
	});
}

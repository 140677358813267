import { createActions } from 'redux-actions';
import { API_PANEL, SETTINGS } from 'store/constants';

const identity = (data) => data;

export default createActions(
	{
		READ: [
			identity, // payload creator
			(payload = {}) => {
				return {
					url: `${API_PANEL}/settings`,
					method: 'GET',
				};
			}, // meta creator
		],
	},
	'READ_SUCCESS',
	'READ_ERROR',
	'CLEAR',
	// Options
	{
		prefix: SETTINGS, // prefix type
	},
);

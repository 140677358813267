/**
 * Homepage selectors
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

// import { useMemo } from 'react';
import { KEY } from './constants';

export const selectSweetAlert = createSelector(
	(state) => state[KEY],
	(sweetAlert) => ({
		title: '',
		...sweetAlert,
	}),
);

export function useSelectSweetAlert() {
	// const select = useMemo(()=>selectSweetAlert);
	return useSelector(selectSweetAlert);
}

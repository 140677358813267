import { useRef, useContext } from 'react';
import { ReactReduxContext } from 'react-redux';

import getInjectors from 'utils/reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */

export default function useInjectReducer({ key, reducer }) {
	const context = useContext(ReactReduxContext);
	const ref = useRef({
		isMount: false,
		injectors: getInjectors(context.store),
	});

	if (!ref.current.isMount) {
		ref.current.injectors.injectReducer(key, reducer);
		ref.current.isMount = true;
	}
}

/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import ErrorBoundary from 'components/ErrorBoundary';
import Footer from 'components/Footer';
import Navbar from 'components/MainNavbar';
import Sidebar from 'components/Sidebar';

import SweetAlert from 'components/SweetAlert';

import ActivatedPage from 'containers/ActivatedPage/Loadable';
import BanPage from 'containers/BanPage/Loadable';
import ChangePasswordPage from 'containers/ChangePasswordPage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage';
import allRoutes from 'containers/routes';
import React, { useEffect, memo, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect } from 'react-router-dom';

import { usePageActions, useAuthActions } from 'store/global/actions';
import { useLocation, useUser, usePartnerUser } from 'store/global/selectors';

import { useAuth } from 'store/global/selectors';

const Layout = memo(function Layout({ routes, route, location }) {
	const pageActions = usePageActions();
	useEffect(() => {
		pageActions.set({
			title: [
				{
					path: route.navTo,
					label: route.navTitle,
				},
			],
		});
	}, [route, pageActions, location.pathname]);

	return (
		<>
			<Sidebar routes={routes} />
			<div className="main-wrapper">
				<Helmet>
					{/* <title>{route.title}</title> */}
					<meta name="description" content={route.description} />
				</Helmet>

				<Navbar fluid routes={routes} route={route} />

				<route.Component route={route} />

				<Footer fluid />
			</div>
		</>
	);
});

export default function App(props) {
	const location = useLocation();
	const user = useUser();
	const partnerUser = usePartnerUser();
	const auth = useAuth();
	const authActions = useAuthActions();

	useEffect(() => {
		if (auth.isAuth) {
			authActions.readUser();
			authActions.partner.readUser();
		}
	}, [auth.isAuth, authActions]);

	const userRoutes = useMemo(() => {
		const result = [];
		allRoutes.forEach((route) => {
			if (route.access.includes('admin')) {
				if (route.backend === 'clo' && (user.access.admin || user.access.master)) {
					result.push(route);
				}
				if (
					route.backend === 'partner' &&
					(partnerUser.access.adminOnly || partnerUser.access.master)
				) {
					result.push(route);
				}
			} else {
				result.push(route);
			}
		});
		return result;
	}, [partnerUser.access, user.access]);

	return (
		<ErrorBoundary>
			<Helmet titleTemplate="DZHAWEB" defaultTitle="DZHAWEB">
				<meta name="description" content="DzhaWeb - интерфейс пользователя" />
			</Helmet>

			<Switch location={location}>
				{!auth.isAuth && [
					<Route path="/restore" exact key="auth.isAuth/ChangePasswordPage">
						<ChangePasswordPage />
					</Route>,
					<Route path="*" key="auth.isAuth/LoginPage">
						<LoginPage />
					</Route>,
				]}
				{user.blocked && (
					<Route path="*">
						<BanPage />
					</Route>
				)}
				{user.active ? (
					<Route path="/activated">
						<Redirect to="/" />
					</Route>
				) : (
					[
						<Route path="/activated" key="user.active/ActivatedPage">
							<ActivatedPage />
						</Route>,
						<Route path="*" key="user.active/Redirect">
							<Redirect to="/activated" />
						</Route>,
					]
				)}
				<Route exact path="/">
					<Redirect to="/clo/stats" />
				</Route>
				{userRoutes.map((route) => {
					return (
						//key - статичный(одинаковый для всех Route)
						//иначе при каждом переходе будет перерендер компонента Layout
						//key можно опустить, но тогда react будет ругаться
						//уникальные key необходимы для "однотипных" DOM-елементов
						//Компонент Route рендерит разные страницы
						//Поэтому можно указать одинаковый key для всех Route
						<Route exact={route.exact} path={route.path} key={'route'}>
							<Layout routes={userRoutes} route={route} location={location} />
						</Route>
					);
				})}
				<Route path="*">
					<NotFoundPage />
				</Route>
			</Switch>

			<SweetAlert />
		</ErrorBoundary>
	);
}

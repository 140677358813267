/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { loadState, saveState } from 'utils/localStorage';
import persistReducer from 'utils/persistReducer';

import { history } from './configureStore';
import { GLOBAL_KEY } from './constants';
import globalReducer from './global/reducer';

/**
 * Creates the main reducer with the dynamically injected ones
 */

const search = new URLSearchParams(window.location.search);
const authParams = search.get('auth');
if (authParams) {
	const auth = JSON.parse(authParams);
	const globalState = loadState(GLOBAL_KEY);
	if (auth && globalState) {
		globalState.auth.payload = {
			...globalState.auth.payload,
			clo: {
				...globalState.auth.payload.clo,
				...auth.clo,
			},
			partner: {
				...globalState.auth.payload.partner,
				...auth.partner,
			},
		};
		saveState(GLOBAL_KEY, globalState);
	}
	search.delete('auth');
	let searchStr = search.toString();
	if (searchStr) {
		searchStr = `?${searchStr}`;
	}
	window.location = `${window.location.origin}${window.location.pathname}${searchStr}`;
}

const global = persistReducer(
	{
		key: GLOBAL_KEY,
	},
	globalReducer,
);

const router = connectRouter(history);

export default function createReducer(injectedReducers) {
	const rootReducer = combineReducers({
		router,
		[GLOBAL_KEY]: global,
		...injectedReducers,
	});

	return rootReducer;
}

// import 'whatwg-fetch';
import { channel } from 'redux-saga';
import { take, race, call, put, select } from 'redux-saga/effects';

import {
	CLIENT_USERNAME,
	CLIENT_PASSWORD,
	PARTNER_CLIENT_PASSWORD,
	PARTNER_CLIENT_USERNAME,
} from 'store/constants';
import authActions from 'store/global/actions/auth';

import { selectTokens, selectPartnerTokens } from 'store/global/selectors';

import parseError from './parseError';

/**
 * API request/response handler
 */

async function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}
	const error = new Error(response.statusText);
	error.connectionEnabled = true;
	error.response = response;

	const contentType = response.headers.get('content-type') || '';
	if (contentType.indexOf('application/json') !== -1) {
		error.body = await response.json();
	}

	throw error;
}

function parseResponse(response) {
	if (response.status === 204 || response.status === 205) {
		return null;
	}
	const contentType = response.headers.get('content-type') || '';
	if (contentType.indexOf('application/json') !== -1) {
		return response.json();
	} else {
		return response;
	}
}

export default function* request(requestURL, options = {}) {
	try {
		options = {
			...options,
			credentials: 'same-origin',
		};

		const requestHeaders = options.headers || {};
		if (requestHeaders['Content-Type'] === 'multipart/form-data') {
			delete options.headers['Content-Type'];
		} else if (!requestHeaders['Content-Type']) {
			requestHeaders['Content-Type'] = 'application/json';
		}

		if (requestHeaders['Content-Type'] === 'application/json') {
			if (typeof options.body === 'object') {
				options.body = JSON.stringify(options.body);
			}
		}

		const response = yield call(fetch, requestURL, options);
		yield checkStatus(response);
		const data = yield parseResponse(response);

		return data;
	} catch (err) {
		const error = parseError(err);
		throw error;
	}
}

export function* requestBasic(requestURL, { backend, ...options }) {
	let password, username;

	if (backend === 'partner') {
		password = PARTNER_CLIENT_PASSWORD;
		username = PARTNER_CLIENT_USERNAME;
	} else {
		password = CLIENT_PASSWORD;
		username = CLIENT_USERNAME;
	}

	options.headers = {
		...options.headers,
		Authorization: 'Basic ' + btoa(username + ':' + password),
	};

	try {
		const data = yield call(request, requestURL, options);
		return data;
	} catch (err) {
		throw err;
	}
}

const isRefreshing = {
	partner: false,
	clo: false,
};

const refreshingChannel = {
	partner: channel(),
	clo: channel(),
};

export function* requestBearer(requestURL, { backend = 'clo', ...options }) {
	if (isRefreshing[backend]) {
		const refreshingState = yield take(refreshingChannel[backend]);
		if (refreshingState.error) {
			throw refreshingState.error.payload.error;
		}
	}

	// Select tokens from store

	let tokens;
	let auth;
	if (backend === 'partner') {
		tokens = yield select(selectPartnerTokens);
		auth = authActions.partner;
	} else {
		tokens = yield select(selectTokens);
		auth = authActions;
	}

	options.headers = {
		...options.headers,
		Authorization: 'Bearer ' + tokens.access_token,
	};

	try {
		return yield call(request, requestURL, options);
	} catch (err) {
		const status = err.response?.status;
		if (status === 401) {
			isRefreshing[backend] = true;
			yield put(auth.refreshLogin(tokens.refresh_token));
			const isLogin = yield race({
				success: take(auth.refreshLoginSuccess),
				error: take(auth.refreshLoginError),
			});

			isRefreshing[backend] = false;
			refreshingChannel[backend].put(isLogin);
			if (isLogin.success) {
				try {
					// const newTokens = yield select(selectTokens);
					const newTokens = isLogin.success.payload;
					options.headers = new Headers({
						...options.headers,
						Authorization: 'Bearer ' + newTokens.access_token,
					});
					return yield call(request, requestURL, options);
				} catch (error) {
					throw error;
				}
			} else {
				throw isLogin.error.payload.error;
			}
		} else {
			throw err;
		}
	}
}

import { useRef, useContext, useEffect } from 'react';
import { ReactReduxContext } from 'react-redux';

import getInjectors from 'utils/sagaInjectors';

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
export default function useInjectSaga({ key, saga, mode, props }) {
	const context = useContext(ReactReduxContext);
	const ref = useRef({
		isMount: false,
		injectors: getInjectors(context.store),
	});

	if (!ref.current.isMount) {
		ref.current.injectors.injectSaga(key, { saga, mode }, props);
		ref.current.isMount = true;
	}
	useEffect(() => {
		const ejectSaga = ref.current.injectors.ejectSaga;
		return () => {
			ejectSaga(key);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

import { handleActions } from 'redux-actions';

import { requestHandler, errorHandler } from 'utils/apiHandlers';

import actions from '../actions/settings';

const initialState = {
	loaded: false,
	landingEditorUrl: 'https://land.dzha.top/api/landingEditor/',
};

export default handleActions(
	{
		// --------  START READ HANDLERS --------
		[actions.read]: requestHandler,
		[actions.readSuccess]: (state, { payload }) => {
			return {
				loaded: true,
				...payload,
			};
		},
		[actions.readError]: errorHandler,
		// -------- END READ HANDLERS --------
		// -------- END DELETE HANDLERS --------
		[actions.clear]: () => initialState,
	},
	initialState,
);

const domain = window.location.hostname.split('.').slice(-2).join('.');
let apiBase = `${window.location.protocol}//clo.${domain}`;

if (process.env.NODE_ENV === 'development') {
	// apiBase = 'http://localhost:3009';
	apiBase = 'https://clo.dzha.top';
}

let apiPartherBase = `${window.location.protocol}//partner.${domain}`;
if (process.env.NODE_ENV === 'development') {
	// apiPartherBase = 'http://localhost:8000';
	apiPartherBase = 'https://partner.dzha.top';
}

export const CLIENT_USERNAME = 'DZHACLO_WEB';
export const CLIENT_PASSWORD = 'Secret-DZHACLO_WEB';

export const API_BASE = `${apiBase}/api`;
export const API_PANEL = `${API_BASE}/panel`;
export const API_PAYMENTS = `${API_BASE}/payments`;

// ======= START GLOBAL KEYS ======= //
export const GLOBAL = 'GLOBAL';
export const GLOBAL_KEY = 'global';
export const AUTH = `${GLOBAL}/AUTH`;
export const PAGE = `${GLOBAL}/PAGE`;
export const SETTINGS = `${GLOBAL}/SETTINGS`;
// ======= END GLOBAL KEYS ======= //

// ======= START INJECTORS CLO KEYS ======= //
export const TEMPLATE = 'TEMPLATE';
export const CAMPAIGN = 'CAMPAIGN';
export const CAMPAIGNS = 'CAMPAIGNS';
export const GEOS = 'GEOS';
export const TRAFFICS = 'TRAFFICS';
export const PAYMENTS = 'PAYMENTS';
export const TARIFFS = 'TARIFFS';
export const CLICK_STATS = 'CLICK_STATS';
// ======= END INJECTORS CLO KEYS ======= //

export const PARTNER_CLIENT_USERNAME = 'Web';
export const PARTNER_CLIENT_PASSWORD = 'Secret-Web';

export const API_PARTNER_BASE = `${apiPartherBase}/api`;

export const API_PARTNER = `${API_PARTNER_BASE}/affiliate`;
export const API_PARTNER_V2 = `${API_PARTNER_BASE}/v2/`;

export const API_PARTNER_SOCKET = apiPartherBase;

// ======= START INJECTORS PARTNER KEYS ======= //
export const PARTNER_SOCKET = 'PARTNER_SOCKET';

export const PARTNER_PWA_OPTIONS = 'PARTNER_PWA_OPTIONS';

export const PARTNER_GEOS = 'PARTNER_GEOS';
export const PARTNER_CURRENCIES = 'PARTNER_CURRENCIES';
export const PARTNER_OFFER = 'PARTNER_OFFER';
export const PARTNER_OFFERS = 'PARTNER_OFFERS';
export const PARTNER_CATEGORIES = 'PARTNER_CATEGORIES';
export const PARTNER_TRACK_LINK = 'PARTNER_TRACK_LINK';
export const PARTNER_TRACK_LINKS = 'PARTNER_TRACK_LINKS';
export const PARTNER_STATS = 'PARTNER_STATS';
export const PARTNER_DOMAINS = 'PARTNER_DOMAINS';
export const PARTNER_ANALYTICS = 'PARTNER_ANALYTICS';
export const PARTNER_STATS_FILTERS = 'PARTNER_STATS_FILTERS';
export const PARTNER_ACTIONS = 'PARTNER_ACTIONS';
export const PARTNER_VISITS = 'PARTNER_VISITS';
export const PARTNER_HOLD_ACTIONS = 'PARTNER_HOLD_ACTIONS';
export const PARTNER_PAYMENTS = 'PARTNER_PAYMENTS';
export const PARTNER_PRE_PAYMENTS = 'PARTNER_PRE_PAYMENTS';
export const PARTNER_TICKETS = 'PARTNER_TICKETS';
export const PARTNER_TICKET_CATEGORIES = 'PARTNER_TICKET_CATEGORIES';
// ======= END INJECTORS PARTNER KEYS ======= //

import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './loading.scss';

export { default as OverlayLoader } from './OverlayLoader';

export default function MyLoadingComponent(props) {
	return (
		<div className="loader">
			<Spinner animation="grow" className="overlay__center" variant="primary" />
		</div>
	);
}

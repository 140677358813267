import React from 'react';
import './error-page.scss';

export default function ErrorPage({ status, message, children }) {
	return (
		<div className="page-error pt-0">
			<div className="page-inner">
				<h1> {status} </h1>
				<h3> {message} </h3>
				<div className="page-description">{children}</div>
			</div>
		</div>
	);
}

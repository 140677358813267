import ErrorPage from 'components/ErrorPage';
import FontAwecomeIcon from 'components/FontAwesomeIcon';
import React from 'react';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
		// You can also log error messages to an error reporting service here
	}

	render() {
		if (this.state.errorInfo) {
			// Error path
			const error = this.state.error || {};
			const status = error.status || error?.response?.status || (
				<FontAwecomeIcon icon="bug" size="lg" />
			);
			const description = error.description || (
				<React.Fragment>
					Очистите кэш браузера и обновите страницу!
					<details style={{ whiteSpace: 'pre-wrap' }}>
						{error.toString()}
						<br />
						{this.state.errorInfo.componentStack}
					</details>
				</React.Fragment>
			);
			return (
				<ErrorPage status={status} message="Что-то пошло не так">
					{description}
				</ErrorPage>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;

import CampaignPage from 'containers/Clo/CampaignPage/Loadable';
import CampaignsPage from 'containers/Clo/CampaignsPage/Loadable';
import FaqPage from 'containers/Clo/FaqPage/Loadable';
import StatsCloPage from 'containers/Clo/StatsCloPage/Loadable';

import SettingsPage from 'containers/SettingsPage/Loadable';
import FinancesPage from 'containers/Web/FinancesPage/Loadable';
import HomePage from 'containers/Web/HomePage/Loadable';
import OffersPage from 'containers/Web/OffersPage/Loadable';
import StatsPage from 'containers/Web/StatsPage/Loadable';
import StatsPageOld from 'containers/Web/StatsPage_Old/Loadable';
import SupportPage from 'containers/Web/SupportPage/Loadable';
import TracklinksPage from 'containers/Web/TracklinksPage/Loadable';

import { matchPath } from 'react-router';

import { v4 as uuid } from 'uuid';

const domain = window.location.hostname.split('.').slice(-2).join('.');

function routeConfig(configs) {
	return configs.map((config) => {
		if (!config.to) config.to = config.path;
		if (!config.navTo) config.navTo = config.to;
		if (!config.navTitle) config.navTitle = config.title;
		config.key = uuid();
		return config;
	});
}

const routes = routeConfig([
	{
		title: 'Статистика CLO',
		description: 'DzhaWeb - Статистика CLO',
		icon: 'chart-line',
		path: '/clo/stats',
		exact: false,
		Component: StatsCloPage,
		access: ['user'],
		group: 'CLO',
	},
	{
		title: 'Новая кампания',
		get navTitle() {
			const match = matchPath(window.location.pathname, {
				path: '/clo/campaigns/:id',
				exact: this.exact,
			});
			if (match && (match.params.id || '').toLowerCase() !== 'new') {
				return 'Мои кампании';
			}
			return this.title;
		},
		description: 'DzhaWeb - Управление кампаниями',
		icon: 'suitcase',
		path: '/clo/campaigns/:id',
		to: '/clo/campaigns/new',
		get navTo() {
			if (this.navTitle === 'Мои кампании') {
				return '/clo/campaigns';
			}
			return this.to;
		},
		exact: true,
		Component: CampaignPage,
		access: ['user'],
		group: 'CLO',
	},
	{
		title: 'Мои кампании',
		description: 'DzhaWeb - Управление кампаниями',
		icon: 'list-ul',
		path: '/clo/campaigns',
		exact: false,
		isActive: (match, location) => {
			if (location.pathname.toLowerCase().includes('/campaigns/new')) {
				return false;
			}
			return !!match;
		},
		Component: CampaignsPage,
		access: ['user'],
		group: 'CLO',
	},
	// {
	//     title: 'Оплата',
	//     description: 'DzhaWeb - Оплата',
	//     icon: 'credit-card',
	//     path: '/clo/payments',
	//     exact: false,
	//     Component: PaymentsPage,
	//     access: ['user'],
	//     group: 'CLO'
	// },
	{
		title: 'Помощь',
		description: 'DzhaWeb - Помощь',
		icon: 'info',
		path: '/clo/faq',
		exact: false,
		Component: FaqPage,
		access: ['user'],
		group: 'CLO',
	},
	{
		title: 'Главная',
		description: 'DzhaWeb - Главная',
		icon: 'home',
		path: '/web/home',
		exact: false,
		Component: HomePage,
		access: ['user'],
		group: 'WEB',
	},
	{
		title: 'Офферы',
		description: 'DzhaWeb - Офферы',
		icon: 'th-large',
		path: '/web/offers',
		exact: false,
		Component: OffersPage,
		access: ['user'],
		group: 'WEB',
	},
	{
		title: 'Потоки',
		description: 'DzhaWeb - Потоки',
		icon: 'clone',
		path: '/web/tracklinks',
		exact: false,
		Component: TracklinksPage,
		access: ['user'],
		group: 'WEB',
	},
	// {
	// 	title: 'Старая Статистика',
	// 	description: 'DzhaWeb - Статистика',
	// 	icon: 'chart-bar',
	// 	path: '/web/stats/old',
	// 	exact: false,
	// 	Component: StatsPageOld,
	// 	access: ['user'],
	// 	group: 'WEB',
	// },
	{
		title: 'Статистика',
		description: 'DzhaWeb - Статистика',
		icon: 'chart-bar',
		path: '/web/stats',
		exact: false,
		Component: StatsPage,
		access: ['user'],
		group: 'WEB',
	},
	{
		title: 'Финансы',
		description: 'DzhaWeb - Финансы',
		icon: 'wallet',
		path: '/web/finances',
		exact: false,
		Component: FinancesPage,
		access: ['user'],
		group: 'WEB',
	},
	{
		title: 'Поддержка',
		description: 'DzhaWeb - Поддержка',
		icon: 'question',
		path: '/web/support',
		exact: false,
		Component: SupportPage,
		access: ['user'],
		group: 'WEB',
	},
	{
		title: 'Настройки',
		description: 'DzhaWeb - Настройки',
		icon: 'user-cog',
		path: '/web/settings',
		exact: false,
		Component: SettingsPage,
		access: ['user'],
		group: 'WEB',
	},
	{
		title: 'В админку CLO',
		description: 'DzhaAdmin - в админку CLO',
		icon: 'exchange-alt',
		path: '/',
		exact: true,
		Component: () => {
			return null;
		},
		href: `${window.location.protocol}//adminclo.${domain}`,
		backend: 'clo',
		access: ['admin'],
		group: 'ADMIN',
	},
	{
		title: 'В админку WEB',
		description: 'DzhaAdmin - в админку Web',
		icon: 'exchange-alt',
		path: '/',
		exact: true,
		Component: () => {
			return null;
		},
		href: `${window.location.protocol}//admin.${domain}`,
		backend: 'partner',
		access: ['admin'],
		group: 'ADMIN',
	},
]);

export default routes;

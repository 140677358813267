/**
 * Gets the repositories of the user from Github
 */
import { compose } from 'redux';
import { channel } from 'redux-saga';
import { put, takeLeading, all, take } from 'redux-saga/effects';

import * as actions from './actions';
import { TRANSFORM } from './constants';

//import invariant from 'invariant';
// const ALLOWS_KEYS = [
//   "type",
//   "info",
//   "success",
//   "warning",
//   "danger",
//   "error",
//   "input",
//   "custom",
//   "title",
//   "onCancel",
//   "onConfirm",
//   "confirmBtnText",
//   "confirmBtnBsStyle",
//   "confirmBtnCssClass",
//   "confirmBtnStyle",
//   "cancelBtnText",
//   "cancelBtnBsStyle",
//   "cancelBtnCssClass",
//   "cancelBtnStyle",
//   "btnSize",
//   "customIcon",
//   "show",
//   "required",
//   "placeholder",
//   "validationMsg",
//   "validationRegex",
//   "defaultValue",
//   "inputType",
//   "style",
//   "closeBtnStyle",
//   "customClass",
//   "showConfirm",
//   "showCancel",
//   "showCloseButton",
//   "allowEscape",
//   "closeOnClickOutside",
//   "hideOverlay",
//   "disabled",
//   "focusConfirmBtn",
//   "beforeMount",
//   "afterMount",
//   "beforeUpdate",
//   "afterUpdate",
//   "beforeUnmount",
//   "timeout",
//   "closeOnConfirm",
//   'message',
//   'className'
// ];

// function getInvalidProps(payload) {
//   return Object.keys(payload).filter(key => ALLOWS_KEYS.indexOf(key) === -1);
// }

// function warningInvalidProps(payload) {
//   const invalidProps = getInvalidProps(payload);
//   invalidProps.forEach(prop => {
//     invariant(false, `Property ${prop} is invalid. You can not pass it to SweetAlert`);
//   });
// }

let _timeout;
const actionChannel = channel();

const closeAlert = () => {
	if (_timeout) {
		clearTimeout(_timeout);
		_timeout = null;
	}
	actionChannel.put(actions.close());
};

const closeOnConfirm = ({ closeOnConfirm, ...payload }) => ({
	...payload,
	onConfirm: (...args) => {
		if (typeof payload.onConfirm === 'function') {
			payload.onConfirm(...args);
		}
		if (closeOnConfirm !== false) {
			closeAlert();
		}
	},
});

const closeOnCancel = ({ closeOnCancel, ...payload }) => ({
	...payload,
	onCancel: (...args) => {
		if (typeof payload.onCancel === 'function') payload.onCancel(...args);
		if (closeOnCancel !== false) {
			closeAlert();
		}
	},
});

const allowEscapeKey = ({ allowEscapeKey, ...payload }) => ({
	...payload,
	onEscapeKey: (...args) => {
		if (typeof payload.onEscapeKey === 'function') payload.onEscapeKey(...args);
		if (allowEscapeKey !== false) {
			closeAlert();
		}
	},
});

const allowOutsideClick = ({ allowOutsideClick, ...payload }) => ({
	...payload,
	onOutsideClick: (...args) => {
		if (typeof payload.onOutsideClick === 'function') payload.onOutsideClick(...args);
		if (allowOutsideClick === true) {
			closeAlert();
		}
	},
});

const timer = ({ timer, ...payload }) => {
	if (timer && typeof timer === 'number') {
		_timeout = setTimeout(closeAlert, timer);
	}
	return payload;
};

export function* transformSwal({ payload }) {
	try {
		// warningInvalidProps(payload);
		const transform = compose(
			closeOnConfirm,
			closeOnCancel,
			allowEscapeKey,
			allowOutsideClick,
			timer,
		)(payload);
		yield put(actions.show(transform));
	} catch (err) {
		yield put(
			actions.show({
				type: 'error',
				title: err.message || err.error_descriprion,
			}),
		);
	}
}
/**
 * Root saga manages watcher lifecycle
 */

export function* watchActionChannel() {
	while (true) {
		const action = yield take(actionChannel);
		yield put(action);
	}
}

export default function* githubData() {
	yield all([watchActionChannel(), takeLeading(TRANSFORM, transformSwal)]);
}

/**
 * index.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import ErrorBoundary from 'components/ErrorBoundary';
import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/App';
import FontFaceObserver from 'fontfaceobserver';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';

// Import CSS reset and Global Styles
import './styles/index.scss';
import 'izitoast/dist/css/iziToast.min.css';

// Import root app

import configureStore, { history } from './store/configureStore';

if (process.env.NODE_ENV !== 'development') {
	let href = window.location.href;
	if (href.includes('http://')) {
		href = href.replace('http://', 'https://');
		//@ts-ignore
		window.location = href;
	}
}

// Observe loading of Montserrat (to remove Montserrat, remove the <link> tag in
// the index.html file and this observer)
// const FontObserver = new FontFaceObserver('Montserrat', {});
const FontObserver = new FontFaceObserver('Nunito', {});
// When Montserrat is loaded, add a font-family using Montserrat to the body
FontObserver.load().then(
	() => {
		document.body.classList.add('fontLoaded');
	},
	() => {
		document.body.classList.remove('fontLoaded');
	},
);

//Create redux store (load state from localStorage)
const initialState = {};

const store = configureStore(initialState);

// ========================================================
// Developer Tools Setup
// ========================================================
// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
//   whyDidYouRender(React, {
//     logOnDifferentValues: false,
//     collapseGroups: true,
//     trackHooks: false,
//     include: [/.*/],
//     exclude: [/^WaitForContainer/, /^RefHolder/, /^Link/, /^LoadableComponent/, /^Router/, /^HighchartsChart/, /^Layout/, /^Switch/, /^Container/]
//   });
//   // whyDidYouRender(React, { exclude: [/^WaitForContainer/, /^RefHolder/, /^Link/, /^LoadableComponent/, /^Router/, /^HighchartsChart/] });
// }
ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

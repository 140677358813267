import Logo from 'assets/wincombo_partners_white (14).png';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import PerfectScrollbar from 'components/Scrollbar';
import groupBy from 'lodash/groupBy';
import React from 'react';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink } from 'react-router-dom';

function NavItem({ to, icon, title, ...props }) {
	return (
		<Nav.Item className="text-nowrap">
			<Nav.Link as={NavLink} to={to} {...props}>
				<div className="nav-link__icon">
					<FontAwesomeIcon icon={icon} />
				</div>
				<span className="nav-link__text">{title}</span>
			</Nav.Link>
		</Nav.Item>
	);
}

class Sidebar extends React.PureComponent {
	state = {
		isHover: false,
	};

	toggle_sidebar_mini(mini) {
		const body = document.body;

		if (!mini) {
			body.classList.remove('sidebar-mini');
		} else {
			body.classList.add('sidebar-mini');
			body.classList.remove('sidebar-show');
		}
	}

	toggleSidebar = (e) => {
		const body = document.body;
		if (window.innerWidth < 992) {
			body.classList.remove('search-show', 'search-gone');
			if (body.classList.contains('sidebar-gone')) {
				body.classList.remove('sidebar-gone');
				body.classList.add('sidebar-show');
			} else {
				body.classList.add('sidebar-gone');
				body.classList.remove('sidebar-show');
			}
		} else {
			body.classList.remove('search-show', 'search-gone');
			if (body.classList.contains('sidebar-mini')) {
				this.toggle_sidebar_mini(false);
			} else {
				this.toggle_sidebar_mini(true);
			}
		}
		setTimeout(() => this.setState((prevState) => ({ isHover: !prevState.isHover })), 300);
	};

	toggleLayout = () => {
		const body = document.body;

		if (window.innerWidth < 992) {
			if (body.classList.contains('sidebar-mini')) {
				this.toggle_sidebar_mini(false);
			}
			body.classList.add('sidebar-gone');
			body.classList.remove('sidebar-mini', 'sidebar-show');
			body.onclick = function (e) {
				if (
					e.target.classList.contains('sidebar-show') ||
					e.target.classList.contains('search-show')
				) {
					e.target.classList.remove('sidebar-show');
					e.target.classList.add('sidebar-gone');
					e.target.classList.remove('search-show');
				}
			};
		} else {
			body.classList.remove('sidebar-gone', 'sidebar-show');
		}
	};

	componentDidMount() {
		this.toggleLayout();
		window.addEventListener('resize', this.toggleLayout);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.toggleLayout);
	}

	render() {
		const routeGroups = groupBy(this.props.routes, 'group');
		return (
			<aside className={`main-sidebar${this.state.isHover ? ' main-sidebar_hover' : ''}`}>
				<Nav className="main-sidebar__head">
					<Nav.Item as={Link} to="/" className="main-sidebar__brand">
						{/* <div className="main-sidebar__brand-logo" /> */}
						{/* DZHAWEB */}
						<Image src={Logo} fluid />
						{/* <FontAwesomeIcon icon={['fab', 'connectdevelop']} size="lg" /> 
                        {' DzhaWeb'} */}
					</Nav.Item>

					<Nav.Item
						className="cursor-poiner main-sidebar-toggle"
						onClick={this.toggleSidebar}
					>
						<FontAwesomeIcon icon={'bars'} size="lg" />
						<span className="main-sidebar-toggle__label">Развернуть</span>
					</Nav.Item>
				</Nav>
				<PerfectScrollbar>
					<Nav className="main-sidebar__menu flex-column">
						{Object.keys(routeGroups).map((group) => {
							return (
								<React.Fragment key={group}>
									<div className="main-sidebar__menu-header">{group}</div>
									{routeGroups[group].map(
										({
											key,
											href,
											to,
											navTo,
											navTitle,
											path,
											Component,
											group,
											backend,
											...route
										}) => {
											if (href) {
												return (
													<Nav.Item key={key} className="text-nowrap">
														<Nav.Link href={href}>
															<div className="nav-link__icon">
																<FontAwesomeIcon
																	icon={route.icon}
																/>
															</div>
															<span className="nav-link__text">
																{route.title}
															</span>
														</Nav.Link>
													</Nav.Item>
												);
											} else {
												return <NavItem key={key} to={to} {...route} />;
											}
										},
									)}
								</React.Fragment>
							);
						})}
					</Nav>
				</PerfectScrollbar>
			</aside>
		);
	}
}

export default Sidebar;

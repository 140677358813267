export const requestHandler = (state, { payload }) => {
	return {
		...state,
		loading: true,
		error: null,
	};
};

export const successHandler = (state, { payload }) => {
	return {
		...state,
		loaded: true,
		loading: false,
		error: null,
		payload,
		updateTime: Date.now(),
	};
};

export const errorHandler = (state, { payload }) => {
	return {
		...state,
		loading: false,
		error: payload,
	};
};

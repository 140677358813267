import { library } from '@fortawesome/fontawesome-svg-core';

import { faConnectdevelop } from '@fortawesome/free-brands-svg-icons/faConnectdevelop';

import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBold } from '@fortawesome/free-solid-svg-icons/faBold';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faBug } from '@fortawesome/free-solid-svg-icons/faBug';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faCubes } from '@fortawesome/free-solid-svg-icons/faCubes';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons/faFileInvoiceDollar';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons/faFingerprint';
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons/faMoneyBill';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { faRemoveFormat } from '@fortawesome/free-solid-svg-icons/faRemoveFormat';
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
import { faRubleSign } from '@fortawesome/free-solid-svg-icons/faRubleSign';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons/faSlidersH';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faStop } from '@fortawesome/free-solid-svg-icons/faStop';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons/faSuitcase';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUserAltSlash } from '@fortawesome/free-solid-svg-icons/faUserAltSlash';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faUserCog } from '@fortawesome/free-solid-svg-icons/faUserCog';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faWallet } from '@fortawesome/free-solid-svg-icons/faWallet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

library.add(
	faBars,
	faConnectdevelop,
	faHome,
	faSignInAlt,
	faThLarge,
	faSignOutAlt,
	faLock,
	faFingerprint,
	faArrowUp,
	faArrowDown,
	faSort,
	faSortUp,
	faSortDown,
	faFilter,
	faUsers,
	faSyncAlt,
	faPencilAlt,
	faPlay,
	faBan,
	faEllipsisH,
	faStop,
	faPlus,
	faChevronDown,
	faCreditCard,
	faEnvelope,
	faSearch,
	faTimes,
	faExchangeAlt,
	faTrash,
	faSave,
	faCubes,
	faExternalLinkAlt,
	faEdit,
	faFileAlt,
	faFileInvoiceDollar,
	faDownload,
	faMoneyBill,

	faBell,
	faExclamation,
	faFire,
	faSuitcase,
	faPlusSquare,
	faListUl,
	faUserPlus,
	faUserCheck,
	faUserAltSlash,
	faRobot,
	faSearch,
	faChevronLeft,
	faChevronRight,
	faTasks,
	faSlidersH,
	faFileContract,
	faArrowRight,
	faArrowLeft,
	faTimesCircle,
	faFileDownload,
	faLayerGroup,
	faReceipt,
	faQuestion,
	faChartBar,
	faCheck,
	faRubleSign,
	faBolt,
	faChartLine,
	faChevronUp,
	faUserCircle,
	faUserCog,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleLeft,
	faAngleRight,
	faBug,
	faBold,
	faRemoveFormat,
	faInfo,
	faClone,
	faWallet,
);

export { FontAwesomeIcon };

const ReactFontAwesomeIcon = React.memo((props) => <FontAwesomeIcon {...props} />);

export default ReactFontAwesomeIcon;

import { put, takeLeading, all } from 'redux-saga/effects';

import actions from '../actions/settings';

function* readHandler() {
	try {
		// const {url, ...options} = meta;
		//TODO заменить заглушку
		// const data = yield call(requestBearer, url, {...options});
		// yield put(actions.readSuccess(data));
		yield put(
			actions.readSuccess({
				landingEditorUrl: 'https://land.dzha.top/api/landingEditor/',
			}),
		);
	} catch (error) {
		yield put(actions.readError(error));
	}
}

export default function* offersSaga() {
	yield all([takeLeading(actions.read, readHandler)]);
}

/*
 * SweetAlertReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { SHOW, CLOSE, CHANGE, LOADING } from './constants';

const initialState = {
	show: false,
	title: '',
	onConfirm: () => {},
};

const ACTION_HANDLER = {
	[LOADING]: (state, { payload }) => {
		return {
			...state,
			show: true,
			type: 'custom',
			custom: true,
			openAnim: null,
			closeOnConfirm: false,
			closeOnCancel: false,
			onConfirm: () => {},
			onCancel: () => {},
			confirmBtnCssClass: 'disabled',
			cancelBtnCssClass: 'disabled',
			customIcon: true,
			...payload,
		};
	},
	[CHANGE]: (state, { payload }) => {
		return {
			...state,
			openAnim: null,
			...payload,
		};
	},
	[SHOW]: (state, { payload }) => {
		return {
			show: true,
			...payload,
		};
	},
	[CLOSE]: () => initialState,
};

export default function extendReducer(state = initialState, action) {
	const handler = ACTION_HANDLER[action.type];
	return handler ? handler(state, action) : state;
}

/**
 * Asynchronously loads the component for StatsPage
 */
import loadable from '@loadable/component';

import Loading from 'components/Loading';
import pMinDelay from 'p-min-delay';
import { timeout, TimeoutError } from 'promise-timeout';

export default loadable(
	() => {
		const timeoutImport = timeout(import('./index'), 10000).catch((err) => {
			if (err instanceof TimeoutError) {
				err.status = 408;
				err.description =
					'Время ожидания ответа истекло, подождите некоторое время и обновите страницу!';
			}
			throw err;
		});
		return pMinDelay(timeoutImport, 200);
		// return timeoutImport;
	},
	{
		fallback: Loading(),
	},
);

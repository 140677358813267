import cs from 'classnames';
import React from 'react';
import { useState, useRef } from 'react';
import { useLayoutEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function OverlayLoader({ className, loading = true, ...props }) {
	const ref = useRef();

	const [style, setStyle] = useState({});

	useLayoutEffect(() => {
		function computedStyle() {
			if (ref.current) {
				const clientHeight = document.documentElement.clientHeight;
				const parentRect = ref.current.parentNode.getBoundingClientRect();
				const style = {};
				if (parentRect.height > clientHeight) {
					if (parentRect.top > 0) {
						style.height = clientHeight - parentRect.top;
					} else {
						style.top = -parentRect.top;
						if (parentRect.bottom > clientHeight) {
							style.height = clientHeight;
						} else {
							style.height = parentRect.bottom;
						}
					}
					setStyle(style);
				}
			}
		}
		computedStyle();
		window.addEventListener('resize', computedStyle);
		window.addEventListener('scroll', computedStyle);
		return () => {
			window.removeEventListener('resize', computedStyle);
			window.removeEventListener('scroll', computedStyle);
		};
	}, []);

	if (loading) {
		return (
			<div
				className={cs('overlay-loader overlay-loader_muted', className)}
				style={style}
				ref={ref}
			>
				<Spinner animation="grow" className="overlay__center" variant="primary" />
			</div>
		);
	} else {
		return null;
	}
}

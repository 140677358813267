import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function LoadingIcon() {
	return (
		<div className="sweet-alert__icon">
			<Spinner animation="grow" variant="primary" size="lg" />
		</div>
	);
}

import { createActions } from 'redux-actions';
import { PAGE as PREFIX } from 'store/constants';

export default createActions(
	'SET', // type = `${GLOBAL}/PAGE/SET`
	'SET_TITLE', // type = `${GLOBAL}/PAGE/SET_TITLE`
	// Options
	{
		prefix: PREFIX, // prefix type
	},
);

import useInjectReducer from 'hooks/useInjectReducer';
import useInjectSaga from 'hooks/useInjectSaga';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { KEY } from './constants';
import LoadingIcon from './LoadingIcon';
import reducer from './reducer';
import saga from './saga';

import { useSelectSweetAlert } from './selectors';
import useSweetAlert from './useSweetAlert';

const ReduxSweetAlert = React.forwardRef(function ReduxSweetAlert(props, ref) {
	useInjectReducer({ key: KEY, reducer });
	useInjectSaga({ key: KEY, saga });
	const sweetAlertState = useSelectSweetAlert();
	let { message, customIcon, ...sweetAlert } = sweetAlertState;
	const { change, loading, ...actions } = useSweetAlert();

	const [scrolled, setScrolled] = useState(!sweetAlert.show);

	useEffect(() => {
		setScrolled(false);
		const timer = setTimeout(() => setScrolled(true), 1000);
		return () => clearTimeout(timer);
	}, [sweetAlertState]);

	if (customIcon === true) {
		customIcon = <LoadingIcon />;
	}

	return (
		<div className={scrolled ? '' : 'sweetalert-wrapper'}>
			<SweetAlert ref={ref} {...actions} {...sweetAlert} customIcon={customIcon} {...props}>
				{message}
			</SweetAlert>
		</div>
	);
});

export default ReduxSweetAlert;

export const dateStrings = {
	hours: ['часов', 'час', 'часа', 'часов'],
	minutes: ['минут', 'минуту', 'минуты', 'минут'],
	day: ['дней', 'день', 'дня', 'дней'],
};
export function getDateString(type, num) {
	const dateString = dateStrings[type];
	if (num > 10 && num < 20) {
		return `${num} ${dateString[0]}`;
	}
	switch (num % 10) {
		case 0:
			return `${num} ${dateString[0]}`;
		case 1:
			return `${num} ${dateString[1]}`;
		case 2:
		case 3:
		case 4:
			return `${num} ${dateString[2]}`;
		case 5:
		case 6:
		case 7:
		case 8:
		case 9:
			return `${num} ${dateString[3]}`;
		default:
			return `${num} ${dateString[0]}`;
	}
}

export default function getExpiresTariff(_tariffExpires, numDays = 15) {
	const tariffExpires = new Date(_tariffExpires);
	let textExpires;
	const today = new Date();
	const expires = tariffExpires.getTime() <= today.getTime();
	if (expires) {
		textExpires = 'Подписка истекла!';
	} else {
		if (today.getFullYear() === tariffExpires.getFullYear()) {
			if (today.getMonth() === tariffExpires.getMonth()) {
				const diffDays = tariffExpires.getDate() - today.getDate();
				if (diffDays === 0) {
					const diffHours = tariffExpires.getHours() - today.getHours();
					if (diffHours === 0) {
						textExpires = `Подписка истекает через ${getDateString(
							'minutes',
							tariffExpires.getMinutes() - today.getMinutes(),
						)}!`;
					} else {
						const diffMinutes = tariffExpires.getMinutes() - today.getMinutes();
						if (diffMinutes >= 0) {
							textExpires = `Подписка истекает через ${getDateString(
								'hours',
								diffHours,
							)} ${getDateString('minutes', diffMinutes)}!`;
						} else if (diffHours - 1 > 0) {
							textExpires = `Подписка истекает через ${getDateString(
								'hours',
								diffHours - 1,
							)} ${getDateString('minutes', 60 + diffMinutes)}!`;
						} else {
							textExpires = `Подписка истекает через ${getDateString(
								'minutes',
								60 + diffMinutes,
							)}!`;
						}
					}
				} else if (diffDays < numDays) {
					textExpires = `Подписка истекает через ${getDateString('day', diffDays)}!`;
				}
			} else if (tariffExpires.getMonth() - today.getMonth() === 1) {
				let lastDay = new Date(tariffExpires);
				lastDay.setDate(0);
				lastDay = lastDay.getDate();
				const diffDays = lastDay - today.getDate() + tariffExpires.getDate();
				if (diffDays < numDays) {
					textExpires = `Подписка истекает через ${getDateString('day', diffDays)}!`;
				}
			}
		} else if (today.getMonth() - tariffExpires.getMonth() === 11) {
			let lastDay = new Date(tariffExpires);
			lastDay.setDate(0);
			lastDay = lastDay.getDate();
			const diffDays = lastDay - today.getDate() + tariffExpires.getDate();
			if (diffDays < numDays) {
				textExpires = `Подписка истекает через ${getDateString('day', diffDays)}!`;
			}
		}
	}
	return textExpires;
}
